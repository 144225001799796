import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class FinancialPlanning extends React.Component {
	render() {
		const siteTitle = 'Financial Planning'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Financial Planning</h3>
								<p>
									Financial planning is much more than choosing products and
									funds. Quality in financial planning is highly personalized to
									your individual needs, goals and preferences.
								</p>
								<p>
									At WSC Group - Wealth Management our goal is for clients to
									retire in a financially secure position with a clear, simple
									and cost effective plan tailored to their individual needs.
								</p>
								<p>
									Our financial planners are CFP qualified, and work closely
									with your accountant to ensure the advice complements your
									current tax planning strategy.
								</p>
								<p>
									Whilst retirement planning is at the forefront of our
									financial planning services; asset protection, estate
									planning, investments and insurance are key components of the
									financial planning process.
								</p>
								<p>
									<strong>We have experts in the fields of:</strong>

									<ul>
										<li>Superannuation</li>
										<li>Insurance</li>
										<li>Investment management</li>
										<li>Self Managed Super Funds</li>
										<li>Estate Planning</li>
										<li>Centrelink – Aged pension advice.</li>
									</ul>
								</p>
								<p>
									To gain an in-depth understanding an initial meeting is
									offered to clients to review their current financial scenario.
									To book your{' '}
									<strong>
										complimentary initial meeting please call 1300 365 125.
									</strong>
								</p>
								<hr />
								<h4 class="text-center">
									WSC Group- Aust Wealth Management is an Authorised
									Representative (No. 001232852 and Credit Representative No.
									478100) of FYG Planners Pty Ltd, AFSL/ACL (No. 224543)
								</h4>
								<hr />
								<h5>
									<strong>Superannuation and Insurance Brochure</strong>
								</h5>
								<p>
									<strong>
										Self Managed Super Funds
										<br />
										<br />
										WSC Group have been setting up and managing the complexities
										of self-managed super funds for over a decade and has built
										a level of expertise well above other accounting firms.
									</strong>
								</p>
								<p>
									WSC Group is known as a SMSF Accounting Specialist and can
									assist in the following areas:
								</p>
								<ul>
									<li>
										Our Financial Planner can assist you to determine whether a
										SMSF is right for you.
									</li>
									<li>
										Our Accounting team can assist in setting up the SMSF and
										appropriate registrations, along with ongoing
										administration, compliance and audit preparation.
									</li>
								</ul>
								<p>
									Our team of SMSF Financial Planners can also advise you on the
									more complicated aspects of SMSFs, including:
								</p>
								<ul>
									<li>Borrowing within your SMSF</li>
									<li>Purchasing property in your SMSF</li>
									<li>Purchasing business premises in your SMSF</li>
									<li>
										Setup of investment portfolios in shares, managed funds,
										bonds and term deposits
									</li>
									<li>Setting up your personal insurances through your SMSF</li>
									<li>Running pensions through your SMSF</li>
									<li>
										Establishment and maintenance of Transition to Retirement
										strategies
									</li>
									<li>Estate Planning within your SMSF</li>
								</ul>
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/421b77f75dbdc21c5721934d8f0d2a54cb22bd4c/self-managed.jpg"
									alt="WSC Finance"
								/>
								<hr />
								<h5>
									<strong>Insurance</strong>
								</h5>
								<strong>
									You already have insurances? When was the last time you
									reviewed your policies?
									<br />
									<br />
									Are you getting the best possible tax benefits from your
									current policies? Did you know you can use part of your
									superannuation to help pay for your insurance?{' '}
								</strong>
								<p>
									As a client of WSC Group we are offering you a complimentary
									review of your current insurance plan by one of our insurance
									specialists. This will ensure you not only have the right
									level of cover but also have the very best value for money,
									and your wealth being structured as tax effectively as
									possible.
								</p>
								<p>
									{' '}
									<strong>
										If you do not already have insurance a WSC Group Wealth
										Management specialist can help you with:
									</strong>
								</p>
								<ul>
									<li>
										{' '}
										<strong>Life Insurance: </strong>A lump sum that is paid out
										when the insured party passes away
									</li>
									<li>
										{' '}
										<strong>TPD Insurance:</strong>A total Permanent Disablement
										lump sum
									</li>
									<li>
										{' '}
										<strong>Income Protection Insurance:</strong>Will assist you
										with ongoing costs such as mortgage repayments if you cannot
										work for a period of time due to sickness or injury
									</li>
									<li>
										{' '}
										<strong>Trauma Insurance:</strong>Covers you for a range of
										pre-determined life threatening illnesses or injuries
									</li>
								</ul>
								<p>
									<i>
										In 2013 a new client to WSC Group – Wealth Management met
										with one of our Insurance specialists to review his existing
										policies. During that meeting he mentioned that 6 months
										earlier he had been diagnosed with cancer and underwent
										radical surgery. This meeting resulted in WSC lodging a
										claim of $110,000 against the client’s existing insurance
										policy. The client did not know he was covered for this and
										within 2 weeks the money was in his bank account.
									</i>
								</p>
								<p>
									<strong>
										For a complimentary initial appointment with one of our WSC
										Financial Planners please contact WSC Group on 1300 365 125
										or email{' '}
									</strong>
									<a href="mailto:info@wscgroup.com.au">info@wscgroup.com.au</a>
								</p>
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/5066df013dc48adce8510286b9c834a859a71dd2/insurance.jpg"
									alt="WSC Finance"
								/>
								<br />
								<br />
								<a
									href="https://drive.google.com/file/d/0B5h0BL-5cRbCZ3pCUHlpUkxkeUU/view"
									target="_blank"
									rel="noopener noreferrer"
								>
									{' '}
									<em class="fa fa-download">&nbsp;</em>Download the PDF file
								</a>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default FinancialPlanning

export const FinancialPlanningPageQuery = graphql`
	query FinancialPlanningPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
